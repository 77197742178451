<template>
  <el-row :gutter="8">
    <!-- <el-col :span="6">
      <router-link to="create-stock">
        <el-button type="primary" :icon="Search">添加黑名单</el-button>
      </router-link></el-col
    >
    <el-col :span="6">
      <router-link to="create-population">
        <el-button type="primary" :icon="Search">添加城市数据</el-button>
      </router-link>
    </el-col> -->
    <el-col :span="10">
      <el-input v-model="keyword" placeholder="输入关键字搜索"> </el-input>
    </el-col>
    <el-col :span="14">
      已拉黑<font size="5" color="#F56C6C">{{ count_stocks }}</font
      >个(<small>关注公众号<font color="green">两亩六分地</font
      >查拉黑原因</small>)
    </el-col>
  </el-row>
  <el-table
    :data="
      stocks.filter(
        (data) =>
          !keyword ||
          data.name.toLowerCase().includes(keyword.toLowerCase())
            | data.code.toLowerCase().includes(keyword.toLowerCase())
      )
    "
    stripe
    style="width: 100%"
  >
    <el-table-column label="股票代码" prop="code" />
    <el-table-column label="名称" prop="name" />
    <el-table-column label="城市" prop="city" />
    <el-table-column label="人口(万)" prop="population" />
    <!-- <el-table-column label="拉黑原因" prop="description" />
    <el-table-column label="操作人">
      <span>新任证监会主席</span>
    </el-table-column>
    <el-table-column align="操作">
      <template #header>
        <el-input v-model="keyword" size="mini" placeholder="Type to search" />
      </template>
      <template #default="scope">
        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
          >Edit</el-button
        >
        <el-button
          size="mini"
          type="danger"
          @click="handleDelete(scope.$index, scope.row)"
          >Delete</el-button
        >
      </template>
    </el-table-column> -->
  </el-table>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      count: null,
      stocks: [],
      initstocks: null,
      loading: true,
      errored: false,
      keyword: "",
    };
  },
  computed: {
    count_stocks: function () {
      if (this.stocks) {
        return this.stocks.length;
      } else return 0;
    },
  },
  methods: {
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    searchStocks: function (value) {
      value = value.trim();
      if (value == null || value === "") {
        this.stocks = this.initstocks;
      }
      this.stocks = this.stocks.filter(
        (item) => item.name.includes(value) | item.code.includes(value)
      );
      return this.stocks;
    },
    cleanKeyword: function () {
      this.keyword = "";
      this.stocks = this.initstocks;
    },
  },
  created() {
    axios
      .get("/api/stocks/stocks-list")
      .then((response) => {
        this.stocks = response.data.data;
        this.initstocks = response.data.data;
        console.log("返回:", this.stocks);
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
};
</script>
